export enum ComplicityEnum {
  ALLIANZ_200 = "ALLIANZ_200",
  ALLIANZ_350 = "ALLIANZ_350",
  ALLIANZ_500 = "ALLIANZ_500",
  CSOB_50 = "CSOB_50",
  CSOB_100 = "CSOB_100",
  CSOB_5_MIN_150 = "CSOB_5_MIN_150",
  CSOB_10_MIN_300 = "CSOB_10_MIN_300",
  GENERALI_150 = "GENERALI_150",
  GENERALI_5_MIN_165 = "GENERALI_5_MIN_165",
  GENERALI_10_MIN_330 = "GENERALI_10_MIN_330",
  GENERALI_5_MIN_66 = "GENERALI_5_MIN_66",
  KOMUNALNA_0_MIN_199 = "KOMUNALNA_0_MIN_199",
  KOMUNALNA_5_MIN_66 = "KOMUNALNA_5_MIN_66",
  KOMUNALNA_5_MIN_100 = "KOMUNALNA_5_MIN_100",
  KOMUNALNA_5_MIN_150 = "KOMUNALNA_5_MIN_150",
  KOMUNALNA_5_MIN_330 = "KOMUNALNA_5_MIN_330",
  KOMUNALNA_5_MIN_165 = "KOMUNALNA_5_MIN_165",
  KOMUNALNA_10_MIN_66 = "KOMUNALNA_10_MIN_66",
  KOMUNALNA_10_MIN_150 = "KOMUNALNA_10_MIN_150",
  KOMUNALNA_10_MIN_165 = "KOMUNALNA_10_MIN_165",
  KOMUNALNA_10_MIN_300 = "KOMUNALNA_10_MIN_300",
  KOMUNALNA_10_MIN_330 = "KOMUNALNA_10_MIN_330",
  KOMUNALNA_10_MIN_500 = "KOMUNALNA_10_MIN_500",
  KOOPERATIVA_150 = "KOOPERATIVA_150",
  KOOPERATIVA_5_MIN_70 = "KOOPERATIVA_5_MIN_70",
  KOOPERATIVA_5_MIN_150 = "KOOPERATIVA_5_MIN_150",
  KOOPERATIVA_5_MIN_160 = "KOOPERATIVA_5_MIN_160",
  KOOPERATIVA_5_MIN_330 = "KOOPERATIVA_5_MIN_330",
  KOOPERATIVA_5_MIN_350 = "KOOPERATIVA_5_MIN_350",
  KOOPERATIVA_10_MIN_330 = "KOOPERATIVA_10_MIN_330",
  KOOPERATIVA_10_MIN_500 = "KOOPERATIVA_10_MIN_500",
  UNION_200 = "UNION_200",
  UNION_10_MIN_350 = "UNION_10_MIN_350",
  UNION_100 = "UNION_100",
  UNIQA_80 = "UNIQA_80",
  UNIQA_200 = "UNIQA_200",
  UNIQA_400 = "UNIQA_400",
  UNIQA_1000 = "UNIQA_1000",
  UNIQA_2000 = "UNIQA_2000",
  UNIQA_2_MIN_80 = "UNIQA_2_MIN_80",
  UNIQA_5_MIN_200 = "UNIQA_5_MIN_200",
  UNIQA_10_MIN_400 = "UNIQA_10_MIN_400",
  UNIQA_15_MIN_1000 = "UNIQA_15_MIN_1000",
  UNIQA_20_MIN_2000 = "UNIQA_20_MIN_2000",
  WUSTENROT_200 = "WUSTENROT_200",
  WUSTENROT_1000 = "WUSTENROT_1000",
  WUSTENROT_5_MIN_150 = "WUSTENROT_5_MIN_150",
  WUSTENROT_10_MIN_300 = "WUSTENROT_10_MIN_300"
}
